import GRID_TYPES from 'CommonUtils/gridTypes';
import API from 'Services/API';

const Service = new API('/api/vuestore');

const plp = () => ({
  namespaced: true,
  state: {
    gridType: GRID_TYPES.LITTLE_PRODUCTS,
    loading: false
  },
  mutations: {
    changeGrid(state, gridType) {
      state.gridType = gridType;
    },
    setLoading(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async changeGrid({ commit }, gridType) {
      await commit('changeGrid', gridType);
    },
    async fetchProducts({ commit }, payload) {
      commit('setLoading', true);
      return await Service.getProductsList(payload)
        .then(result => {
          return result;
        })
        .catch(() => {})
        .finally(() => {
          commit('setLoading', false);
        });
    },
    fetchProductsBySearch({ commit }, payload) {
      commit('setLoading', true);
      return Service.getProductsListBySearch(payload)
        .then(result => {
          return result;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    }
  },
  getters: {
    getGridType(state) {
      return state.gridType;
    },
    getIsLoadingPlP(state) {
      return state.loading;
    }
  }
});

export default plp;
