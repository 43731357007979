import newsletter from './modules/newsletter';
import page from './modules/page';
import support from './modules/support';
import cart from 'CommonStore/cart';
import wishlist from 'CommonStore/wishlist';
import buyLater from './modules/buyLater';
import uiState from 'CommonStore/uiState';
import toastMessageState from 'CommonStore/toastMessageState';
import selectorState from 'CommonStore/selectorState';
import filters from 'CommonStore/filters';
import layout from 'CommonStore/layout';
import analytics from 'CommonStore/analytics';
import plp from './modules/plp';
import icons from './modules/icons';

export default {
  modules: () => ({
    newsletter: newsletter(),
    page: page(),
    support: support(),
    cart: cart(),
    uiState: uiState(),
    toastMessageState: toastMessageState(),
    selectorState: selectorState(),
    wishlist: wishlist(),
    buyLater: buyLater(),
    filter: filters(),
    'search-filters': filters(),
    layout: layout(),
    analytics: analytics(),
    plp: plp(),
    icons: icons(),
  }),
};
