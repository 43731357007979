const getJSONItem = (key) => {
  let item = window.localStorage.getItem(key);
  if(item) { 
    item = JSON.parse(item);
  } else {
    item = {};
  }
  return item;
};

const setJSONItem = (key, data) => {
  let item = getJSONItem(key);
  item = JSON.stringify({...item, ...data });
  window.localStorage.setItem(key, item);
  return getJSONItem(key);
};

const updateJSON = (key, data) => {
  const item = getJSONItem(key);
  const newItem = setJSONItem(key, {...item, ...data });
  return newItem;   
};

const getItem = (key) => {
  return window.localStorage.getItem(key);
};

const setItem = (key, data) => {
  window.localStorage.setItem(key, data);
};

export default {
  getJSONItem,
  setJSONItem,
  updateJSON,
  getItem,
  setItem
}
