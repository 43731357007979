const { productCustomizationMapper } = require('./productCustomization.mapper');
const stockUtils = require('../schemas/stock');
const { uniqBy } = require('../operations/uniqBy');

function skuColorsMapper(
  skus,
  defaultColor,
  skuFavoriteList = {},
  getOnlyPlainImages,
  providers,
  selectedProvider,
  merchandiser = undefined,
  crossSellingByColor,
  maxAdditionalImages,
  customization
) {  
  const colorsList = skus
    .filter((sku) => sku.color)
    .map(function (sku) {
      return {
        cross_selling: sku.cross_selling || [],
        title: sku.color.title,
        thumbnail_url: sku.color.thumbnail_url,
        image: sku.color.image,
        all_images: getSkuImages(sku) || [],
        common_sku: sku.reference && sku.reference.common,
        is_favorite: sku.reference && skuFavoriteList && !!skuFavoriteList[sku.reference.common],
        all_preview_images: getSkuPreviewImages(sku, getOnlyPlainImages, merchandiser, maxAdditionalImages) || [],
        hideColor: sku.color.hideColor,
        videos: sku.videos,
        onlyMarketplaceOffers: sku.only_marketplace_offers,
        discount: sku.discount,
        price: sku.price,
        sale_price: sku.sale_price,
        id: sku.id,
        add_to_cart: sku.add_to_cart,
        flags: sku.flags,
        delivery_options: sku.delivery_options,
        custom_service: !!productCustomizationMapper(sku?.custom_service, sku.reference_id),
        from_searcher: sku.from_searcher,
        offer_type: sku.offer_type,
        offer_id: sku.offer_id,
      };
    });
  const fullList = skus;
  const colorListSortedByNumberImage = colorsList.sort((a, b) => {
    return b.all_images.length - a.all_images.length;
  });
  const uniqColorList = uniqBy(colorListSortedByNumberImage, 'title');

  const fullColor = uniqColorList.map(function (uniqColor) {
    const allSkusInColor = fullList.filter((sku) => sku.color && sku.color.title === uniqColor.title);
    const colorStatus = stockUtils.getSkusGroupStatus(allSkusInColor.map((sku) => sku.add_to_cart));
    uniqColor.not_available = !stockUtils.hasAvailableStatus(colorStatus);
    uniqColor.coming_soon = stockUtils.hasComingSoonStatus(colorStatus);
    uniqColor.showPrice = stockUtils.hasToShowPrice(colorStatus);
    const variantList = {
      variants: fullList
        .filter(function (sku) {
          return sku.color && sku.color.title && sku.color.title === uniqColor.title;
        })
        .map(function (sku) {
          const title = sku._variant_title || 'Variante';
          const skuProviders = getProviderExtraInfo(sku.providers, providers, selectedProvider);
          if (!sku.variant || !sku.variant.length) {
            const emptyVariant = {
              description: '',
              title: title,
              value: `${title} única`,
              unique: true,
              empty: true,
            };
            return {
              ...getSkuVariantData(sku, customization),
              variant: emptyVariant,
              providers: skuProviders,
            };
          }
          return {
            ...getSkuVariantData(sku, customization),
            variant: getVariantObjectFormatted(sku),
            type: title,
            providers: skuProviders,
          };
        }),
    };
    return Object.assign(uniqColor, variantList);
  });
  if (fullColor && fullColor.length) {
    setPreselectedColor(fullColor, defaultColor);
    setPriceRangeInColor(fullColor);
    setCrossSellingForColor(fullColor, crossSellingByColor);
  }

  return fullColor.sort((a, b) => b.preselected - a.preselected);
}

function getSkuVariantData(sku, customization) {
  return {
    add_to_cart: sku.add_to_cart,
    color: sku?.color,
    common_sku: sku.common_sku,
    delivery_options: sku.delivery_options,
    delivery_time_with_stock: sku.delivery_time_with_stock,
    delivery_time_with_stock_exact_time: sku.delivery_time_with_stock_exact_time,
    delivery_time_without_stock: sku.delivery_time_without_stock,
    delivery_time_without_stock_exact_time: sku.delivery_time_without_stock_exact_time,
    derivation_delivery_time_with_stock: sku.derivation_delivery_time_with_stock,
    discount: sku.discount,
    eci_unique_code: sku.eci_unique_code,
    flags: sku.flags,
    from_searcher: sku.from_searcher,
    id: sku.id,
    model_id: sku.mpn,
    isMarketplace: sku.offer_type == 'MKP',
    gift_promotions: sku?.gift_promotions,
    gtin: sku.gtin,
    offer_id: sku.offer_id,
    offer_type: sku.offer_type,
    pumOrPackComposition: sku.pumOrPackComposition,
    price: sku.price,
    principal_gif: sku?.principal_gif,
    promos: sku?.promos,
    productCustomization: productCustomizationMapper(sku?.custom_service, sku.reference_id),
    custom_service: !!productCustomizationMapper(sku?.custom_service, sku.reference_id),
    reference: sku.reference_id,
    sale_price: sku.sale_price,
    slot_initial_date_with_stock: sku.slot_initial_date_with_stock,
    slot_initial_date_without_stock: sku.slot_initial_date_without_stock,
    sku: sku?.id?.trim(),
    skuForSimilarImages: getSkuForSimilarImages({
      sku: sku,
      custom: customization 
    }),
    sku_promo_badge: sku?.sku_promo_badge,
    status: sku.status,
    stockReference: sku.stock_reference,
    attributes: sku.attributes,
    all_promos: sku?.all_promos,
  };
}

function getSkuForSimilarImages({sku, custom}){
  if(!sku || !sku.reference || !sku.gtin || !sku.offer_type){
    return null;
  }
  const { reference, gtin, offer_type } = sku;
  const isMKP = offer_type == 'MKP' ? true : false;
  if(isMKP && gtin){
    return gtin;
  }
  if(!custom || !custom.similar_images_common_sku){
    return reference.bySku;
  }
  return reference.common;
}

function getVariantObjectFormatted(variant) {
  let text = variant && variant._variant_text ? variant._variant_text : 'Variante única';
  let title = variant && variant._variant_title ? variant._variant_title : 'Variante';
  // Move to a custom formatter by type of size
  if (text.indexOf('Copa') !== -1) {
    text = text.split(' - Copa ').join('');
    title = title.trim().split('-')[0];
  }
  return {
    title: title,
    value: text,
    description: variant._variant_units,
    price: variant.price,
    sale_price: variant.sale_price,
    unique: text.toLowerCase().indexOf('única') > 1,
    empty: !variant._variant_text || variant._variant_text?.trim() === '',
  };
}

function getProviderExtraInfo(skuProviders, providersWithExtraInfo, selectedProvider) {
  if (skuProviders && Array.isArray(skuProviders)) {
    skuProviders.forEach((provider) => {
      const providerExtraData =
        providersWithExtraInfo &&
        providersWithExtraInfo.find((providerWithFullData) => providerWithFullData.provider_id == provider.provider_id);
      if (providerExtraData) {
        provider.extraInfo = {
          name: providerExtraData.business_name,
          address: providerExtraData.address,
          postalCode: providerExtraData.postal_code,
          country: providerExtraData.country,
          city: providerExtraData.city,
          cif: providerExtraData.cif,
        };
        provider.stockReference = provider.offer ? provider.offer.ean_provider + ':' + provider.provider_id : '';
        provider.selected = providerExtraData.provider_id.toString() === selectedProvider;
      }
    });
  }
  return skuProviders;
}

function setPreselectedColor(colorList, defaultColor) {
  let colorHasBeenPreselected = false;

  if (!defaultColor) {
    colorList.forEach((color) => {
      color.preselected = false;
    });
    if (colorList.length) {
      colorList[0].preselected = true;
    }
    colorHasBeenPreselected = true;
  }

  !colorHasBeenPreselected &&
    colorList &&
    colorList.forEach((color) => {
      color.preselected = color.title === defaultColor && !color.not_available;
    });

  const existColorWithPreselected = colorList.some((color) => color.preselected === true);

  if (!existColorWithPreselected) {
    colorList.forEach((color) => {
      if (!color.not_available && !colorHasBeenPreselected) {
        color.preselected = true;
        colorHasBeenPreselected = true;
      }
    });

    if (!colorHasBeenPreselected && colorList && colorList.length && colorList.length > 0) {
      const defaultSelectedColor = defaultColor && colorList.find((color) => color.title === defaultColor);
      if (defaultSelectedColor) {
        defaultSelectedColor.preselected = true;
      } else {
        colorList[0].preselected = true;
      }
      colorHasBeenPreselected = true;
    }
  }
}

const _hasPriceVariant = (variant) => variant.hasOwnProperty('price') && variant.price;

const hasPriceRange = (firstVariant, availableVariants) => {
  let priceToCompareFirstVariant, priceToCompareAvailableVariants;
  if (firstVariant.providers && firstVariant.providers.length && firstVariant.providers[0].offer) {
    const firstVariantOffer = firstVariant.providers[0].offer;

    priceToCompareFirstVariant = !firstVariantOffer.sale_price ? firstVariantOffer.price : firstVariantOffer.sale_price;
    priceToCompareAvailableVariants = availableVariants.map((variant) => {
      if (variant.providers && variant.providers.length && variant.providers[0].offer) {
        return !variant.providers[0].offer.sale_price
          ? variant.providers[0].offer.price
          : variant.providers[0].offer.sale_price;
      }
    });
  } else {
    priceToCompareFirstVariant = !firstVariant.sale_price ? firstVariant.price : firstVariant.sale_price;
    priceToCompareAvailableVariants = availableVariants.map((variant) =>
      !variant.sale_price ? variant.price : variant.sale_price
    );
  }
  return priceToCompareAvailableVariants.some((price) => price !== priceToCompareFirstVariant);
};

function setPriceRangeInColor(fullColor) {
  fullColor.forEach((color) => {
    const hasVariants = color.variants && Array.isArray(color.variants) && color.variants.length > 0;
    if (!hasVariants) return;
    const availableVariants = color.variants.filter(_hasPriceVariant);

    const firstVariant = availableVariants.find(_hasPriceVariant);
    if (!firstVariant) return;
    color.hasPriceRange = hasPriceRange(firstVariant, availableVariants);
  });
}

function getSkuImages(sku) {
  // TODO: Eliminar el chequeo del dominio una vez se arregle el problema del DNS
  const BANNED_DOMAIN_1 = 'sgdimgperrest.eci.geci';
  const BANNED_DOMAIN_2 = 'sgdimgperrest.paas.eci.geci';

  if (sku) {
    const images = [];
    sku.image && images.push(sku.image);
    if (sku.additional_images && sku.additional_images.length) {
      sku.additional_images.forEach((additionalImage) => {
        if (
          !additionalImage?.default_source?.includes(BANNED_DOMAIN_1) &&
          !additionalImage?.default_source?.includes(BANNED_DOMAIN_2)
        ) {
          images.push(additionalImage);
        }
      });
    }
    sku.plain_image && sku.plain_image.default_source && images.push(sku.plain_image);
    if (sku.additional_plain_images && sku.additional_plain_images.length) {
      sku.additional_plain_images.forEach((additionalPlainImage) => {
        images.push(additionalPlainImage);
      });
    }
    if (sku.look_images && sku.look_images.length) {
      sku.look_images.forEach((lookImage) => {
        images.push(lookImage);
      });
    }
    return images;
  }
  return [];
}

function getSkuPreviewImages(sku, getOnlyPlainImages, merchandiser, maxAdditionalImages) {
  // Foto principal / image_link
  // Foto secundaria 1 / aditional_imagen_link 1
  // Foto secundaria 2 / aditional_imagen_link 2
  // Foto principal en plano / plain_image

  if (!sku) return [];
  const images = [];
  if (merchandiser?.color && merchandiser.color === sku.color?.title) {
    images.push(merchandiser);
  }
  if (!getOnlyPlainImages || !sku.plain_image || !sku.plain_image.sources || !sku.plain_image.sources.big) {
    sku.image && images.push(sku.image);
  }
  if (!getOnlyPlainImages && sku?.additional_images?.length) {
    images.push(...sku.additional_images.slice(0, maxAdditionalImages));
  }
  if (sku.plain_image && Object.keys(sku.plain_image).length) {
    images.push(sku.plain_image);
  }

  return getWithoutDuplicates(images, 'default_source');
}

function getWithoutDuplicates(arr, param) {
  return arr.filter((item, index, self) => index === self.findIndex((t) => t[param] === item[param]));
}

function setCrossSellingForColor(fullColor, crossSellingsByColor) {
  if (crossSellingsByColor && Array.isArray(crossSellingsByColor)) {
    crossSellingsByColor.forEach((crossSellingByColor) => {
      fullColor
        .filter((color) => color.title == crossSellingByColor.color)
        ?.forEach((selectedColor) => {
          selectedColor.cross_selling = crossSellingByColor.products;
        });
    });
  }
}

module.exports = {
  skuColorsMapper,
  hasPriceRange: hasPriceRange,
};
