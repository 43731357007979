import ServiceAPI from './ServiceAPI';
import UtilsCart from 'CommonUtils/cartUrl';

class CartService extends ServiceAPI {
  constructor(endpoint) {
    super(endpoint);
  }

  getCart(params) {
    return this.get('/cart', params);
  }

  async processToCheckout(params) {
    const { user_status, guest_email } = params;
    const path = '/processToCheckout';
    const endpoint = `${path}`;
    return await this.post({ user_status, guest_email }, endpoint);
  }

  async addToCart(params) {
    const path = '/cart/add' + UtilsCart.getCartPath(params);
    const endpoint = `${path}`;
    return await this.post(params, endpoint);
  }

  postCart(params) {
    const path = UtilsCart.getCartPath(params);
    const endpoint = `/cart${path}`;
    return this.post(params, endpoint);
  }

  async updateCartItem(params) {
    const path = '/cart/update' + UtilsCart.getCartPath(params);
    const endpoint = `${path}`;
    return await this.post(params, endpoint);
  }

  postEciExpress(value) {
    return this.post({ eci_express: value }, '/cart?');
  }

  setEciExpressInOrderDelivery({ postal_code, order_attempt_id, order_delivery_id }) {
    const path = `/${order_attempt_id}/set-order-delivery/`;
    if (order_delivery_id) path.concat(order_delivery_id);
    const endpoint = `${path}`;
    return this.post({ delivery_address: { postal_code }, delivery_method: { name: 'eci_express' } }, endpoint);
  }

  setClickAndCarInOrderDelivery({ center, order_attempt_id, order_delivery_id }) {
    const { general_centre, company } = center;
    const path = `/${order_attempt_id}/set-order-delivery/`;
    if (order_delivery_id) path.concat(order_delivery_id);
    const endpoint = `${path}`;
    return this.post(
      {
        delivery_method: {
          name: 'click_and_car',
        },
        pickup_store: {
          identifier: general_centre,
          company,
        },
      },
      endpoint
    );
  }

  async updatePickupStoreInOrderDelivery({ center, order_attempt_id, order_delivery_id }) {
    const { general_centre, company } = center;
    const path = `/${order_attempt_id}/patch-order-delivery/${order_delivery_id}`;
    const endpoint = `${path}`;
    const operations = [
      {
        op: 'replace',
        path: '/pickup_store/identifier',
        value: general_centre
      },
      {
        op: 'replace',
        path: '/pickup_store/company',
        value: company
      }
    ];
    return await this.patch(operations, endpoint)
  }

  patchEciExpressInOrderDelivery({ postal_code, order_attempt_id, order_delivery_id }) {
    const path = `/${order_attempt_id}/patch-order-delivery/${order_delivery_id}`;
    const endpoint = `${path}`;
    return this.patch(
      [
        { op: 'replace', path: '/delivery_address/postal_code', value: postal_code },
      ],
      endpoint
    );
  }

  async patchTimeSlotsInOrderDelivery({ time_slot, order_attempt_id, order_delivery_id }) {
    const { start_date, end_date, is_immediate_delivery } = time_slot;
    const path = `/${order_attempt_id}/patch-order-delivery/${order_delivery_id}`;
    const endpoint = `${path}`;
    const operations = [
      {
        op: 'replace',
        path: '/delivery_time_slot/expected_arrival_from',
        value: start_date,
      },
      {
        op: 'replace',
        path: '/delivery_time_slot/expected_arrival_until',
        value: end_date,
      },
      {
        op: 'replace',
        path: '/delivery_time_slot/is_immediate_delivery',
        value: is_immediate_delivery,
      },
    ];
    return await this.patch(operations, endpoint);
  }

  postClickAndCar(value) {
    return this.post({ click_and_car: value }, '/cart?');
  }

  async putCart(options) {
    const path = UtilsCart.getCartPath(options);
    const endpoint = `/cart${path}`;
    return await this.put(options, endpoint);
  }

  deleteCartItem(params) {
    const path = '/cart/delete-item' + UtilsCart.getCartPath(params);
    const endpoint = `${path}`;
    return this.post(params, endpoint);
  }

  deleteCartItemOmns(options) {
    const path = UtilsCart.getCartPath(options);
    const endpoint = `/cart/${path}`;
    return this.post(options, endpoint);
  }

  async getTimeSlots(params) {
    return await this.get(UtilsCart.getTimeSlotPath(params));
  }

  async getTimeSlotsClicknCar() {
    const endpoint = '/time_slots?modeTimeSlots=clickAndCar';

    return await this.get(endpoint);
  }

  async getMrcaInfo() {
    const endpoint = '/mrca/info';
    return await this.get(endpoint);
  }

  async updateCartShippingMethod(shippingMethods) {
    const params = { ...shippingMethods };
    return await this.postCart(params);
  }
}

export default CartService;
