import UtilFilters from 'CommonUtils/filters.js';
import { DELIVERY_PARAMS, DELIVERY_TIME } from 'CommonUtils/schemas/facetDelivery';

const filters = () => ({
  namespaced: true,
  state: {
    menubar: undefined,
    boxesChecked: [],
    boxesFiltered: undefined,
    filters: [],
    currentFilterUrl: '',
    countResultFilters: 0,
    isSearchPage: false,
    searchTerm: '',
    stype: '',
    sortings: [],
    selected_sorting: '',
    loading: false,
    sortHasBeenClickedByUser: false,
    activeUrl: '',
    numPagesOnView: 1,
    isOpenTypeahead: false,
    sitesChips: [],
    categoriesList: [],
    anyTerm: false,
    showAlternativeSearchText: false,
    activeFilterModuleName: 'filter',
    deliveryFacet: null,
    urlSupermarket: '',
    loginRedirectUrl: '',
    logoutRedirectUrl: '',
    blockSelected: 'general'
  },
  getters: {
    getLoginRedirectUrl(state) {
      return state.loginRedirectUrl;
    },
    getLogoutRedirectUrl(state) {
      return state.logoutRedirectUrl;
    },
    getActiveUrl(state) {
      return state.activeUrl;
    },
    getNumPagesOnView(state) {
      return state.numPagesOnView;
    },
    getLoadingFilter(state) {
      return state.loading;
    },
    getMenuBar(state) {
      return state.menubar;
    },
    getSortHasBeenClickedByUser(state) {
      return state.sortHasBeenClickedByUser;
    },
    getBoxesChecked(state) {
      return state.boxesChecked;
    },
    getFilters(state) {
      return state.filters;
    },
    getCurrentFilterUrl(state) {
      return state.currentFilterUrl;
    },
    getCountResultFilters(state) {
      return state.countResultFilters;
    },
    getSearchTerm(state) {
      return state.searchTerm || '';
    },
    getStype(state) {
      return state.stype;
    },
    getIsSearchPage(state) {
      return state.isSearchPage;
    },
    getSorting(state) {
      return state.sortings;
    },
    getSelectedDeliveryType(state) {
      return state.selectedDeliveryType;
    },
    getCurrentlySelectedDeliveryTypes(state) {
      return state.currentlySelectedDeliveryTypes;
    },
    getSelectedSorting(state) {
      return state.selected_sorting;
    },
    getIsSelectedDelivery(state) {
      const deliveryTimeFilters = state.filters.filter((filter) => filter.id?.includes(DELIVERY_TIME.DELIVERY_KEY));
      return state.selectedDeliveryType === DELIVERY_PARAMS.DELIVERY && deliveryTimeFilters.length <= 2;
    },
    getIsSelected24Or48h(state) {
      const deliveryTimeFilters = state.filters.filter((filter) => filter.id?.includes(DELIVERY_TIME.DELIVERY_KEY));
      return (
        (!state.selectedDeliveryType || state.selectedDeliveryType === DELIVERY_PARAMS.DELIVERY_KEY) &&
        deliveryTimeFilters.length === 1
      );
    },
    getIsSelectedClickAndCar(state) {
      const deliveryTimeFilters = state.filters.filter((filter) => filter.id?.includes(DELIVERY_TIME.DELIVERY_KEY));
      return state.selectedDeliveryType === DELIVERY_PARAMS.COLLECT && deliveryTimeFilters.length <= 2;
    },
    getIsSelectedDeliveryAndCollect(state) {
      const deliveryTimeFilters = state.filters.filter((filter) => filter.id?.includes(DELIVERY_TIME.DELIVERY_KEY));
      return state.selectedDeliveryType === DELIVERY_PARAMS.DELIVERY_AND_COLLECT && deliveryTimeFilters.length <= 3;
    },
    getIsOpenTypeahead(state) {
      return state.isOpenTypeahead;
    },
    getSitesChips(state) {
      return state.sitesChips;
    },
    getActiveFilterModuleName(state) {
      return state.activeFilterModuleName;
    },
    getDeliveryFacet(state) {
      return state.deliveryFacet;
    },
    getCategoriesList(state) {
      return state.categoriesList;
    },
    getIsAlternativeSearch(state) {
      return state.anyTerm;
    },
    getShowAlternativeSearchText(state) {
      return state.showAlternativeSearchText;
    },
    getUrlSupermarket(state) {
      return state.urlSupermarket;
    },
    getBlockSelected(state) {
      return state.blockSelected;
    },
  },
  mutations: {
    SET_LOGIN_URL(state, data) {
      state.loginRedirectUrl = data;
    },
    SET_LOGOUT_URL(state, data) {
      state.logoutRedirectUrl = data;
    },
    INIT_FILTERS(state, data) {
      state.menubar = data._menubar || [];
      state.countResultFilters = data.pagination.count || 0;
      state.isSearchPage = data._is_search_page;
      state.searchTerm = data._searching_term;
      state.sortings = data._sortings || [];
      state.selected_sorting = data._selected_sorting || {};

      const activeData = [];
      if (data._filters) {
        data._filters.forEach((e) => {
          e.values.forEach((b) => {
            activeData.push(b);
          });
        });
      }
      let trail = data._filters_trail ? [...data._filters_trail] : [];
      if (data?._internals?.req?.moonshine?.slugs?.filters?.length) {
        const currentFilters = data._internals.req.moonshine.slugs.filters;
        const previousFilters = trail;
        trail = UtilFilters.merge(previousFilters, currentFilters);
      }
      state.filters = activeData || [];
      state.boxesChecked = activeData || [];      
      state.currentFilterUrl = (trail || []).join('/') || null;
    },

    INIT_SEARCH_FILTERS(state, data) {
      const activeBlock = data?.block || {};
      state.menubar = activeBlock?._menubar || [];
      state.countResultFilters = activeBlock?.pagination?.count || 0;
      state.isSearchPage = true;
      state.searchTerm = data.searchTerm || '';
      state.stype = data.stype || '';
      state.sortings = activeBlock?._sortings || [];
      state.selected_sorting = activeBlock?._selected_sorting || {};
      state.deliveryFacet = activeBlock?._delivery_facet ?? activeBlock?.delivery_facet;
      const activeData = [];
      if (activeBlock?._filters) {
        activeBlock?._filters.forEach((e) => {
          e.values.forEach((b) => {
            activeData.push(b);
          });
        });
      }
      if (activeBlock?._filters_trail) {
        let trail = [...activeBlock._filters_trail];
        if (data?.internals?.req?.moonshine?.slugs?.filters?.length) {
          const currentFilters = data.internals.req.moonshine.slugs.filters;
          const previousFilters = [...activeBlock._filters_trail];
          trail = UtilFilters.merge(previousFilters, currentFilters);
        }
        state.currentFilterUrl = (trail || []).join('/') || '';
      }
      state.filters = activeData || [];
      state.boxesChecked = activeData || [];
    },

    SET_ACTIVE_URL(state, value) {
      state.activeUrl = value;
    },

    SORT_HAS_BEEN_CLICKED_BY_USER(state, hasBeenClickedByUser) {
      state.sortHasBeenClickedByUser = hasBeenClickedByUser;
    },

    ADD_SELECTED_SORTING(state, selectedSorting) {
      state.selected_sorting = selectedSorting;
    },

    ADD_CURRENT_URL(state, url) {
      state.currentFilterUrl = url;
    },

    REMOVE_CURRENT_URL(state) {
      state.currentFilterUrl = '';
    },

    ADD_ITEM_CHECKED(state, box) {
      state.boxesChecked = [...state.boxesChecked, box];
    },

    REMOVE_ITEM_CHECKED(state, box) {
      state.boxesChecked = state.boxesChecked.filter((item) => box._fid !== item._fid);
    },

    REMOVE_FILTER(state, box) {
      state.filters = state.filters.filter((item) => box._fid !== item._fid);
    },

    RESET_BOXES_CHECKED(state) {
      state.boxesChecked = [];
      state.filters = [];
    },

    LOADING_FILTERS(state, value) {
      state.loading = value;
    },

    SELECTED_DELIVERY_TYPE(state, value) {
      state.selectedDeliveryType = value;
    },

    CURRENTLY_SELECTED_DELIVERY_TYPES(state, value) {
      state.currentlySelectedDeliveryTypes = value || [];
    },

    RESET_STATE(state) {
      state.boxesChecked = [];
      state.filters = [];
      state.currentFilterUrl = '';
      state.selected_sorting = '';
      state.sorting_filters = '';
    },
    SET_SEARCH_FILTERS(state, value) {
      state.searchTerm = value.term;
      state.stype = value.stype;
    },
    SET_NUM_PAGES_ON_VIEW(state, value) {
      state.numPagesOnView = value;
    },
    SET_IS_OPEN_TYPEAHEAD(state, value) {
      state.isOpenTypeahead = value;
    },
    SET_SITES_CHIPS(state, value) {
      state.sitesChips = value;
    },
    SET_CATEGORIES_lIST(state, value) {
      state.categoriesList = value;
    },
    SET_IS_ALTERNATIVE_SEARCH(state, value) {
      state.anyTerm = value;
    },
    SET_SHOW_ALTERNATIVE_SEARCH_TEXT(state, value) {
      state.showAlternativeSearchText = value;
    },
    SET_ACTIVE_FILTER_MODULE_NAME(state, value) {
      state.activeFilterModuleName = value;
    },
    SET_DELIVERY_FACET(state, delivery_facet) {
      state.deliveryFacet = delivery_facet;
    },
    UPDATE_DELIVERY_FACET_ITEM(state, { type, key, index, prop, value }) {
      let deliveryItem = state.deliveryFacet[type]?.[key]?.[index]?.[prop];
      if (deliveryItem) {
        deliveryItem = value;
      }
    },
    UPDATE_URL_SUPERMARKET(state, url) {
        state.urlSupermarket = url;
    },
    SET_BLOCK_SELECTED(state, value) {
      state.blockSelected = value;
    },
  },
  actions: {
    async setLogoutRedirectUrl({ commit }, filter_data) {
      await commit('SET_LOGOUT_URL', filter_data);
    },
    async setLoginRedirectUrl({ commit }, filter_data) {
      await commit('SET_LOGIN_URL', filter_data);
    },
    async initFilters({ commit }, filter_data) {
      await commit('INIT_FILTERS', filter_data);
    },

    async initSearchFilters({ commit }, filter_data) {
      await commit('INIT_SEARCH_FILTERS', filter_data);
    },
    async setSearchTerm({ commit }, payload) {
      await commit('SET_SEARCH_FILTERS', payload);
    },
    async setSelectedSorting({ commit }, payload) {
      await commit('ADD_SELECTED_SORTING', payload);
    },

    async setSortHasBeenClickedByUser({ commit }, payload) {
      await commit('SORT_HAS_BEEN_CLICKED_BY_USER', payload);
    },

    async resetBoxesChecked({ commit }) {
      await commit('RESET_BOXES_CHECKED');
    },

    async addCurrentUrl({ commit }, url) {
      await commit('ADD_CURRENT_URL', url);
    },

    async removeCurrentUrl({ commit }, url) {
      await commit('REMOVE_CURRENT_URL', url);
    },

    async addItemChecked({ commit }, item) {
      await commit('ADD_ITEM_CHECKED', item);
    },

    async removeItemChecked({ commit }, item) {
      await commit('REMOVE_ITEM_CHECKED', item);
    },

    async removeFilter({ commit }, item) {
      await commit('REMOVE_FILTER', item);
    },

    async setLoadingFilter({ commit }, value) {
      await commit('LOADING_FILTERS', value);
    },

    setSelectedDeliveryType({ commit }, value) {
      commit('SELECTED_DELIVERY_TYPE', value);
    },

    setCurrentlySelectedDeliveryTypes({ commit }, value) {
      commit('CURRENTLY_SELECTED_DELIVERY_TYPES', value || []);
    },

    async resetState({ commit }) {
      await commit('RESET_STATE');
    },

    async setActiveUrl({ commit }, value) {
      await commit('SET_ACTIVE_URL', value);
    },

    async setNumPagesOnView({ commit, state }, payload) {
      if (state.numPagesOnView < payload.page || payload.reset) {
        await commit('SET_NUM_PAGES_ON_VIEW', payload.page);
      }
    },
    setIsOpenTypeahead({ commit }, value) {
      commit('SET_IS_OPEN_TYPEAHEAD', value);
    },
    setSitesChips({ commit }, value) {
      commit('SET_SITES_CHIPS', value);
    },
    setActiveFilterModuleName({ commit }, value) {
      commit('SET_ACTIVE_FILTER_MODULE_NAME', value);
    },
    setIsAlternativeSearch({ commit }, value) {
      commit('SET_IS_ALTERNATIVE_SEARCH', value);
    },
    setShowAlternativeSearchText({ commit }, value) {
      commit('SET_SHOW_ALTERNATIVE_SEARCH_TEXT', value);
    },
    async setDeliveryFacet({ commit }, delivery_facet) {
      await commit('SET_DELIVERY_FACET', delivery_facet);
    },
    async updateDeliveryFacetItem({ commit }, { type, key, index, prop, value }) {
      await commit('UPDATE_DELIVERY_FACET_ITEM', { type, key, index, prop, value });
    },
    updateUrlSupermarket({ commit }, params) {
      commit('UPDATE_URL_SUPERMARKET', params);
    },
    setBlockSelected({ commit }, value) {
      commit('SET_BLOCK_SELECTED', value);
    },
  },
});

export default filters;
